import { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import axios from 'axios';
import Select from 'react-select';
import * as Yup from 'yup';
import { IoSearchCircleOutline } from 'react-icons/io5';
import { fontSize, fontWeight } from '@mui/system';
import { alphabeticOrder } from '../utils/utils';
import {
  Values, ValuesCategorie, FilterProps, ValuesFacilities,
} from '../components/Interfaces';
import Loader from './Loader';
import { find, set } from 'lodash';
import { useNavigate } from 'react-router-dom';

async function delay(ms: number) {
  console.log('Aqui antes del await');
  await new Promise((resolve) => setTimeout(resolve, ms));
  console.log('Aqui despues del await');
}

const Filter = ({
  id,
  doctorFilter,
  clientData,
  dataLabels,
  setDataHour,
  isCharge,
  dataHour,
}: FilterProps) => {
  const [loadingFacilities, setLoadingFacilities] = useState<boolean>(false);
  const [showFacility, setShowFacility] = useState<boolean>(false);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [specialties, setEspecialties] = useState<Values[]>([{ value: null, label: '' }]);
  const [categories, setCategories] = useState<[ValuesCategorie]>([{ value: null, label: 'Selecciona una prestacion' }]);
  const [doctors, setDoctors] = useState<[Values]>([{ value: null, label: 'Selecciona un doctor' }]);
  const [sucursales, setSucursales] = useState<ValuesFacilities[]>([{} as ValuesFacilities]);
  const [sucursalesDispobibles, setSucursalesDisponibles] = useState<ValuesFacilities[]>([{} as ValuesFacilities]);
  const [showDoctors, setShowDoctors] = useState<boolean>(false);
  const [loadingDoctors, setLoadingDoctors] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (dataHour.specialty) {
        findCategory(dataHour.specialty);
      }
      if (dataHour.facility) {
        setShowFacility(true);
      }
      if (dataHour.categorie) {
        setShowCategories(true);
        findFacilitiesByCategorie(dataHour.categorie, clientData.edad);
      } else {
        await findFacilities();
      }
      findSpeciality(id);
    };

    fetchData();
  }, []);


  const findFacilities = async () => {
    try {
      const response = await axios.post('https://alma.maiposalud.com/alma/external/pp/v1/facility', '', {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
        },
      });
      const sucursalesList: ValuesFacilities[] = [];
      const { data } = response;
      if (Array.isArray(data)) {
        data.forEach((item: any) => sucursalesList.push({
          value: item.id,
          label: item.name,
          street: item.street,
          state: item.state,
        }));
      }
      sucursalesList.sort(alphabeticOrder);
      setSucursalesDisponibles(sucursalesList);
      return sucursalesList;
    } catch (err) {
      setSucursalesDisponibles([]);
      console.error(err);
    }
    return [];

  };

  const findSpeciality = (idF: string) => {
    axios.post('https://alma.maiposalud.com/alma/external/v1/specialtyWeb', {
      group: idF,
    }, {
      headers: {
        Authorization: '2374dfa16c7475154d0e3539a7bcf785',
        'Content-Type': 'multipart/form-data',
      },
    }).then((response: any) => {
      const dataFilter: Array<unknown> = response.data;
      const data: Values[] = [];
      if (Array.isArray(dataFilter)) {
        dataFilter.forEach((item: any) => data.push({
          value: item.option_id,
          label: item.title,
        }));
      }
      setEspecialties(data);
    }).catch((err) => console.error(err));
  };

  async function findCategory(specialty: any) {
    setLoadingCategories(true);
    await axios.post('https://alma.maiposalud.com/alma/external/pp/v1/categories',
      {
        specialty,
      },
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then((response: any) => {
        const dataFilter: Array<unknown> = response.data;
        const data = Array.isArray(dataFilter)
          ? dataFilter.filter((item: any) => item.visible_agenda_web === 1) : [];
        const categorias: any = [];
        data.forEach((item: any) => {
          const name = item.pc_catname;
          categorias.push({
            value: {
              id: item.pc_catid,
              preparacion: item.pc_preparacion,
            },
            label: name,
          });
          setLoadingCategories(false);
          setShowCategories(true);
        });
        categorias.sort(alphabeticOrder);
        setCategories(categorias);
      }).catch((err) => console.error(err));
  }

  const findFacilitiesByCategorie = async(categorie: string, age: string) => {
    const facilities = await findFacilities();
    setLoadingFacilities(true);
    await axios.post('https://alma.maiposalud.com/alma/external/v1/nextHourCategories', {
      categorie,
      age,
    }, {
      headers: {
        Authorization: '2374dfa16c7475154d0e3539a7bcf785',
        'Content-Type': 'multipart/form-data',
      },
    }).then((response: any) => {
      const { data } = response;
      const sucursalesId = Array.isArray(data)
        ? data.map((sucursal: any) => +sucursal.facility)
        : [];
      const sucursalesFiltradas = facilities.filter(
        (sucursal: any) => sucursalesId.includes(sucursal.value),
      );
      sucursalesFiltradas.sort(alphabeticOrder);
      setSucursales(sucursalesFiltradas);
      setLoadingFacilities(false);
      setShowFacility(true);
    }).catch((err) => console.error(err));
  };

  const findDoctors = (facility: any, category: any) => {

    setLoadingDoctors(true);
    axios.post('https://alma.maiposalud.com/alma/external/pp/v1/facilityCategorie',
      {
        facility,
        categorie: category,
      },
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then((response: any) => {
        const { data } = response;
        const doctorsList: any = [];
        data.forEach((item: any) => {
          doctorsList.push({ value: item.id, label: `${item.nombre} ${item.apellido}` });
        });
        doctorsList.sort(alphabeticOrder);
        setDoctors(doctorsList);
        setLoadingDoctors(false);
        setShowDoctors(true);
        setLoadingFacilities(false);
      }).catch((err) => console.error(err));
  };

  const colourStyles = {
    control: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'white',
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      fontWeight: 'lighter',
      fontSize: '14px',
    }),
    menu: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'white',
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      fontWeight: 'lighter',
      fontSize: '14px',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#80808060',
      fontWeight: 'lighter',
      fontSize: '14px',
    }),

  };

  const FilterSchema = Yup.object().shape({
    edad: Yup.number()
      .required('Este campo es requerido')
      .typeError('La edad debe ser un número')
      .min(0, 'La edad mínima es 0 años'),
    especialidad: Yup.string()
      .required('Este campo es requerido'),
    categoria: Yup.string()
      .required('Este campo es requerido'),
    sucursal: Yup.string()
      .required('Este campo es requerido'),
    doctor: doctorFilter ? Yup.string().required('Este campo es requerido') : Yup.string(),
  });

  return (
    <div className="filterFormContainer">
      <Formik
        initialValues={{
          especialidad: dataHour.specialty, edad: clientData.edad, categoria: dataHour.categorie, sucursal: dataHour.facility, doctor: '', preparacion: dataHour.preparacion,
        }}
        validationSchema={FilterSchema}
        onSubmit={(values) => {
          setDataHour({
            specialty: values.especialidad,
            categorie: values.categoria,
            facility: values.sucursal,
            doctor: values.doctor,
            preparacion: values.preparacion,
            dataLabels,
          });
          navigate('/filter', { state: { message: `${id}`, clientData } });
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} className="form">
            <label className="labelForm">Especialidad</label>
            <Select
              value={specialties.find((specialtie) => specialtie.value === values.especialidad)}
              placeholder="Seleccione Especialidad"
              className="formSelector"
              options={specialties}
              styles={colourStyles}
              onChange={async (specialtie: any) => {
                setShowFacility(false);
                values.sucursal = '';
                values.categoria = '';
                await findCategory(specialtie.value);
                values.especialidad = specialtie.value;
                dataLabels.specialty = specialtie.label;
              }}
            />
            <label className="labelForm">Prestación</label>
            <Select
              value={values.categoria && categories.find((categorie) => categorie.value?.id === values.categoria)}
              placeholder={loadingCategories ? 'Cargando prestaciones...' : 'Selecciona una prestacion'}
              className="formSelector"
              styles={colourStyles}
              options={categories}
              onChange={(categorie: any) => {
                values.sucursal = '';
                setSucursales([]);
                if (values.edad) {
                  findFacilitiesByCategorie(categorie.value.id, values.edad);
                } else {
                  findFacilitiesByCategorie(categorie.value.id, '100');
                }
                values.categoria = categorie.value.id;
                dataLabels.category = categorie.label;
                values.preparacion = categorie.value.preparacion;
              }}
              isDisabled={!showCategories}
            />
            <label className="labelForm">Sucursal</label>
            <Select
              value={values.sucursal && sucursales.find((sucursal) => sucursal.value === values.sucursal)}
              placeholder={loadingFacilities ? 'Cargando sucursales...' : 'Selecciona una sucursal'}
              className="formSelector"
              options={sucursales}
              styles={colourStyles}
              isDisabled={!showFacility}
              onChange={(sucursal: any) => {
                findDoctors(sucursal.value, values.categoria);
                values.sucursal = sucursal.value;
                dataLabels.facility.name = sucursal.label;
                dataLabels.facility.street = sucursal.street;
                dataLabels.facility.state = sucursal.state;
              }}
            />
            {doctorFilter
              ? (
                <>
                  <label className="labelForm">Profesional</label>
                  <Select
                    placeholder={loadingDoctors ? 'Cargando doctores...' : 'Selecciona un doctor'}
                    className="formSelector"
                    options={doctors}
                    isDisabled={!showDoctors}
                    onChange={(doctor: any) => {
                      values.doctor = doctor.value;
                    }}
                    styles={colourStyles}
                  />
                </>
              ) : null}
            <label className="labelForm">Edad</label>
            <Field
              id="edad"
              name="edad"
              type="number"
              placeholder={values.edad ? values.edad.toString() : 'Seleccione Edad'}
              className="formSelector"
              onChange={(e: any) => {
                const edad = e.target.value;
                values.edad = edad;
                clientData.edad = edad;
              }}
            />
            {errors.edad === 'La edad debe ser un número' ? (
              <div className="error">{errors.edad}</div>
            ) : null}
            {isCharge
              ? (<Loader color="#f9f9f9" />)
              : (
                <button type="submit" className="buttonSubmit btnBuscar">
                  Buscar
                </button>
              )}
            {(errors.edad || errors.categoria
              || errors.especialidad || errors.sucursal
              || errors.doctor) && errors.edad !== 'La edad debe ser un número'
              ? (<div className="error">Debes rellenar todos los campos</div>)
              : null}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Filter;
