import { useState, useEffect, Fragment } from 'react';
import { Grid, Paper, styled } from '@mui/material';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import axios from 'axios';
import '../styles/HourCard.css';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import docImage from '../assets/images/male-doctor-icon-free-vector.jpg';
import { DataHour, HourCardProps } from './Interfaces';
import 'dayjs/locale/es';
import PopUpConfirmation from './PopUpConfirmation';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
  weekdays: [
    'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado',
  ],
});

const Item = styled(Paper)(({ theme }: any) => ({
  backgroundColor: '#079ae7',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  fontWeight: 'bold',
  pointer: 'cursor',

}));

const HourCard = ({
  docName,
  dataHour,
  dataClient,
  dataLabels,
}: HourCardProps) => {
  const [pagina, setPagina] = useState<number>(1);
  const [porPagina] = useState<number>(16);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hourSelected, setHourSelected] = useState<DataHour>({} as DataHour);
  const [foto, setFoto] = useState<string>(docImage);
  const closeModal = () => setIsOpen(false);
  const docRut = dataHour[0].rutProfesional;
  const maximo = dataHour.length / porPagina;

  const openModalConfirmation = (openModalConfirmationData: DataHour) => {
    setHourSelected(openModalConfirmationData);
    setIsOpen(true);
  };

  useEffect(() => {
    setPagina(1);
    getProfessionalInfo()
  }, [dataHour]);

  const getProfessionalInfo = () => {
    axios.post('https://alma.maiposalud.com/alma/external/pp/v1/professional', { rut: docRut }, {
      headers: {
        Authorization: '2374dfa16c7475154d0e3539a7bcf785',
        'Content-Type': 'multipart/form-data',
      },
    }).then((r: any) => {
      if (r.data[0].foto != "" && r.data[0].foto != null) {
        setFoto(r.data[0].foto);
      }
    });
  };
  return (
    <div
      key={docName}
      className="hourContainer"
    >
      <div className="hourCardBody">
        <div className="docContainer">
          <p className="docInfo">{docName}</p>
          <p className="docSpecialty">{dataLabels.specialty.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
        </div>
        <div className="hourProfessionalCard">
          <div className='imageDocContainer'>
            <img
              className="imgDoc"
              src={foto}
              alt="Imagen Doctor"

            />
          </div>
          <div style={{ flex: 5, display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={1} marginY={2} columns={12} className="gridHourCard">
              {dataHour.slice((pagina - 1) * porPagina + (pagina != 1 ? -(2 * (pagina - 1) - 1) : 0), (pagina - 1) * porPagina + (pagina < Math.ceil(maximo) && pagina > 1 ? porPagina - (2 * pagina - 1) : porPagina - 1)).map(
                (d: DataHour) => (
                  <Fragment key={d.reservationHour}>
                    <Grid key={docName} item xs={3} sm={3} lg={3}>
                      <Item
                        key={d.reservationHour?.substring(10, 16)}
                        className="item"
                        onClick={() => { openModalConfirmation(d); }}
                      >
                        {' '}
                        {d.reservationHour?.substring(10, 16)}
                        {' '}
                      </Item>
                    </Grid>
                  </Fragment>
                ),
              )}
              <div className={pagina > 1 ? "bottomCard" : "bottomCardDisplayNone"}>
                {pagina > 1 ? <button type="button" className="nextPageButton" onClick={() => setPagina(pagina - 1)}><MdKeyboardDoubleArrowLeft className="icon" /></button> : null}
                {/* {pagina < Math.ceil(maximo) ? <button className="nextPageButton" type="button" onClick={() => setPagina(pagina + 1)}><MdKeyboardDoubleArrowRight className="icon" /></button> : null} */}
              </div>
              <div className={pagina < Math.ceil(maximo) ? "bottomCard" : "bottomCardDisplayNone"}>
                {/* {pagina > 1 ? <button type="button" style={{borderTopRightRadius: 5}} className="nextPageButton" onClick={() => setPagina(pagina - 1)}><MdKeyboardDoubleArrowLeft className="icon" /></button> : null} */}
                {pagina < Math.ceil(maximo) ? <button className="nextPageButton" type="button" onClick={() => {
                  setPagina(pagina + 1)
                }}><MdKeyboardDoubleArrowRight className="icon" /></button> : null}
              </div>
            </Grid>
            {/* <div className="bottomCard">
              {pagina > 1 ? <button type="button" style={{borderTopRightRadius: 5}} className="nextPageButton" onClick={() => setPagina(pagina - 1)}><MdKeyboardDoubleArrowLeft className="icon" /></button> : null}
              {pagina < Math.ceil(maximo) ? <button className="nextPageButton" type="button" onClick={() => setPagina(pagina + 1)}><MdKeyboardDoubleArrowRight className="icon" /></button> : null}
            </div> */}
          </div>
          <PopUpConfirmation
            isOpen={isOpen}
            closeModal={closeModal}
            hourSelected={hourSelected}
            dataLabels={dataLabels}
            dataClient={dataClient}
          />
        </div>
      </div>
    </div>
  );
};

export default HourCard;
