import { useState } from 'react';
import axios from 'axios';
import maipoLogo from '../assets/images/LogoMaiposalud.png';
import '../styles/ConfirmationPage.css';

const HourConfirmationPage = () => {
  const [rut, setRut] = useState('');
  const [, setOpenForm] = useState(false);

  const handleSubmit = () => {
    axios.post('https://alma.maiposalud.com/alma/external/pp/v1/patientData',
      {
        rut,
      },
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then().catch(() => {
        setOpenForm(true);
      });
  };

  return (
    <div className="appBody">
      <div style={{
        width: '50%', marginTop: '10%', height: '50vh', backgroundColor: '#1a72bd', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: '7px',
      }}
      >
        <div style={{
          flex: 1, justifyContent: 'center', display: 'flex', backgroundColor: 'white', height: '100%', alignItems: 'center', borderBottomLeftRadius: '7px', borderTopLeftRadius: '7px',
        }}
        >
          <img alt="" src={maipoLogo} width="90%" height="60%" />
        </div>
        <div style={{
          flex: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
        >
          <div style={{
            width: '70%', height: '80%', color: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column',
          }}
          >
            <h4>Datos del Paciente</h4>
            <p>Ingrese su RUT y verifique sus datos para continuar.</p>
            <input
              style={{
                width: '94.5%', marginTop: '5%', borderRadius: '5px', border: '0', height: '20px',
              }}
              onChange={(value: any) => setRut(value.target.value)}
            />
            <button type="button" onClick={() => handleSubmit()} className="searchButton">Buscar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HourConfirmationPage;
